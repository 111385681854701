<template>
  <ul class="setting-sidebar">
        <div class="li-top"></div>
        <router-link to="/user/personal">
          <li class="setting-sidebar-li">个人设置</li>
        </router-link>
        <router-link to="/user/resetPassword">
          <li class="setting-sidebar-li">密码设置</li>
        </router-link>
      </ul>
</template>