<template>
  <div class="container" v-loading="loading">
    <div class="row">
      <div class="col-xs-3 col-sm-3 col-md-3">
        <userSiderbar />
      </div>
      
      <div class="col-xs-9 col-sm-9 col-md-9">
        <form @submit.prevent="handleSubmit">
          <div class="form-group row">
            <div class="col-sm-12">
              <input type="text" class="form-control" v-model="username"  placeholder="用户名" />
            </div>
          </div>
          <div class="form-group row">
            <div class="col-sm-12">
              <input type="text" class="form-control" v-model="userDescription" placeholder="简介" />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-success right">提交</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import userSiderbar from "@/views/user/user-sidebar.vue";
import { ElMessage } from 'element-plus'

export default {
  components: {
    userSiderbar,
  },
  data() {
    return {
      username: '',
      userDescription: '',
      loading: false,
    };
  },
  async created() {
    if (localStorage.getItem("username") != "null") {
      this.username = localStorage.getItem("username");
    }
    if (localStorage.getItem("userDescription") != "null") {
      this.userDescription = localStorage.getItem("userDescription");
    }
    // this.loading = false
  },
  methods: {
    tigs(msg, type) {
      ElMessage({
        message: msg,
        type: type,
      })
    },
    async handleSubmit() {
      const response = await axios.post("/api/web/auth/userUpdateInfo", {
        username: this.username,
        description: this.userDescription,
      });

      // console.log(response);
      if (response.data.code == 200) {
        localStorage.setItem('username', this.username);
        localStorage.setItem('userDescription', this.userDescription);
        this.tigs(response.data.message, 'success')
        // this.$router.push('/');
      } else {
        this.tigs(response.data.message, 'warning')
      }
    },
  },
};
</script>
